@import url('../src/shared/styles/_global.scss');

* {
  font-family: 'Montserrat';
}

*::-webkit-scrollbar {
  background-color: #d1d1d1;
  border-radius: 20px;
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #322f77;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  width: 5px;
  height: 5px;
}

html {
  overflow: hidden;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./assets/fonts/Montserrat-Medium.ttf) format('truetype');
}