* {
  --primary-color: #f6b200;
  --secondary-color: #322f87;
  --background-color: #f5f5f5;
  --black-color: #000000;
  --gray-color: #e4e4e4;
  --red-color: #FF0000;
  --border-color: #d9d9d9;
}

.border-blue {
  border-top: solid 1px #322F87 !important;
}

.text-black {
  color: var(--black-color);
}

.text-gray {
  color: #41464b;
}

.text-gray-light {
  color: #bbbbbb;
}

.cursor:hover {
  cursor: pointer;
}

.no-cursor:hover {
  cursor: default;
}

.modal-buttons {
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.form-input-select {
  height: 45px;
  border: 1px solid #bdbdbd;
  border-radius: 97.66px !important;
  color: #000000;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 12px;
  outline: none;
}

#modal {
  z-index: 10;
}

.page-container {
  width: 100vw;
  display: flex;
  flex-direction: row;
}

.page-content {
  background-color: var(--background-color);
  margin-top: 2.8rem;
  margin-bottom: 1rem;
  height: calc(100vh - 4.43rem);
  overflow: auto;
  width: 100%;
  transition: width 0.3s ease-in-out;
  border-top: solid 1px #d9d9d9;
}

.text-primary {
  color: #322f87 !important;
}

.invisible {
  visibility: hidden;
}

textarea {
  resize: none !important;
  outline: none;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: none !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #322f8740 !important;
}

.form-select:focus {
  border-color: none !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #322f8740 !important;
}

.not-allowed {
  cursor: not-allowed;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.justify-center {
  justify-content: center;
}

.tooltip {
  max-width: 500px !important;
  min-width: 100px !important;
}

.tooltip-inner {
  max-width: none !important;
  font-size: 10px;
}

.required::after {
  content: ' *';
  color: red;
}

.grid-checkbox {
  display: grid;
  grid-template-columns: 18px auto;
  grid-template-rows: 1fr;
  align-items: center;
}
